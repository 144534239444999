<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo" />
				</div>
				<div class="columns column3">
					<h4>{{ defaults[locale].website.restaurantName }}</h4>
					<p class="address">
						<span>
							{{ defaults[locale].website.street }}&nbsp; {{ defaults[locale].website.number }}&nbsp;
						</span>
						<span>
							{{ defaults[locale].website.postalCode }}&nbsp;{{ defaults[locale].website.city }}&nbsp;
						</span>
						<span>{{ defaults[locale].website.country }}</span>
					</p>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon class="icon icon-phone" icon="fal fa-phone" size="1x" />
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon class="icon icon-mail" icon="fal fa-envelope" size="1x" />
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<div v-parse-links v-html="defaults[locale].website.footerOpeningHours" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('quickLinks') }}</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
					</p>
				</div>
			</div>
		</footer>
		<footer class="author-footer">
			<div class="row">
				<div class="columns column12">
					<div class="border" />
					<p>
						<a
							href="https://www.becurious.com/"
							target="_blank"
							rel="noopener noreferrer"
							title="Hotel website design by becurious"
						>
							Website Design
						</a>
						<span>by:</span>
						<a href="https://www.becurious.com/" target="_blank">
							<img
								src="~/assets/images/logo-becurious-white.png"
								loading="lazy"
								alt="Hotel website design by becurious"
							/>
						</a>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
.footer-logo {
	background: url('~/assets/images/logo-cafepanache-full-white.png') no-repeat center center;
	background-size: 202px;
	width: 202px;
	height: 102px;
	transition: all 0.3s ease-in-out;
}

.footers {
	color: #fff;
	background: $footer-background-color;

	a,
	span,
	p,
	:deep(p) {
		color: $footer-color;
		text-decoration: none;
	}

	h3,
	h4,
	:deep(h3),
	:deep(h4) {
		color: #fff;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
}

.main-footer {
	padding: 75px 0;
	font-size: 16px;

	p,
	:deep(p) {
		font-size: 0.9em;
	}

	a {
		display: block;

		svg {
			color: $cta-color;
			margin: 0 10px 0 0;
		}
	}

	a:hover {
		text-decoration: underline;
	}

	.footer-logo {
		max-width: 180px;
		margin: 0 0 30px;
	}
}

.author-footer {
	font-size: 0.8em;
	padding: 5px 0;
	text-align: center;
	width: 100%;

	p {
		margin: 15px 0;
	}

	.border {
		background: $primary-background-color;
		width: 100%;
		height: 1px;
	}

	img {
		max-width: 90px;
		margin: -1px 1px 3px 2px;
	}
}

@media (max-width: 1080px) {
	footer .columns {
		margin: 0 auto 20px;
	}
}

@media (max-width: 640px) {
	.main-footer {
		padding: 30px 5px;
		text-align: center;

		.footer-logo {
			margin: 0 auto 20px;
		}

		.socials {
			justify-content: center;
		}
	}
}
</style>
